import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Auth } from "aws-amplify";
import {
  Button,
  Flex,
  Heading,
  Text,
  CheckboxField,
  SelectField,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { findCampaignRequest } from "./graphql/queries";
import {
  createCampaignRequest
} from "./graphql/mutations";

const App = ({ signOut }) => {
  const [notes, setNotes] = useState([]);
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    //fetchNotes();
    checkUser();
  }, []);

  // async function fetchNotes() {
  //   const apiData = await API.graphql({ query: listNotes });
  //   const notesFromAPI = apiData.data.listNotes.items;
  //   setNotes(notesFromAPI);
  // }

  async function checkUser() {
    let curUser = await Auth.currentAuthenticatedUser();
    setUsername(curUser.username);
    setUserEmail(curUser.attributes.email);
}

  async function createCampaign(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data =
    {
      name: form.get("name"),
      requesterEmail: form.get("requesterEmail"),
      requesterName: form.get("requesterName"),
      requestedLaunchDate: new Date(form.get("requestedLaunchDate")).toISOString(),
      campaignType: form.get("campaignType"),
      campaignDetail: {
        subjectLine: form.get("subjectLine"),
        seedlist: form.get("seedlist"),
        hasLegalSignoff: form.get("hasLegalSignoff") == "true" ? true : false,
        hasAwardsAndAccolades: form.get("hasAwardsAndAccolades") == "true" ? true : false,
        goals: form.get("goals"),
        audience: form.get("audience"),
        approvalList: form.get("approvalList"),
        ctaLink: form.get("ctaLink")
      },
      eventAddress: {
        venueName: form.get("venueName"),
        venueAddress1: form.get("venueAddress1"),
        venueAddress2: form.get("venueAddress2"),
        venueCity: form.get("venueCity"),
        venueState: form.get("venueState"),
        venueZip: form.get("venueZip")
      },
      eventTimeline: {
        firstReminderDate: new Date(form.get("firstReminderDate")).toISOString(),
        secondReminderDate: new Date(form.get("secondReminderDate")).toISOString(),
        rsvpCloseDate: new Date(form.get("rsvpCloseDate")).toISOString(),
        waitlistCloseDate: new Date(form.get("waitlistCloseDate")).toISOString()
      },
      rsvpDetail: {
        eventName: form.get("eventName"),
        ticketType: form.get("ticketType"),
        ticketRedemptionInst: form.get("ticketRedemptionInst"),
        extraCADTickets: form.get("extraCADTickets") == "true" ? true : false
      }

    };
    await API.graphql({
      query: createCampaignRequest,
      variables: { input: data },
    });
    //fetchNotes();
    event.target.reset();
  }

  // async function deleteNote({ id }) {
  //   const newNotes = notes.filter((note) => note.id !== id);
  //   setNotes(newNotes);
  //   await API.graphql({
  //     query: deleteNoteMutation,
  //     variables: { input: { id } },
  //   });
  // }

  return (
    <View className="App">
      <Heading level={1}>Campaign Request Form</Heading>
      <View as="form" margin="3rem 0" onSubmit={createCampaign}>
        <Heading level={4}>Campaign Header</Heading>
        <Flex direction="column" justifyContent="center">
          <Flex direction="row" justifyContent="center">
            <TextField name="name" placeholder="Campaign Name" label="Campaign Name" variation="default" required />
            <TextField name="requesterEmail" placeholder="Requester Email" label="Requester Email" variation="default" value={userEmail} readOnly required />
            <TextField name="requesterName" placeholder="Requester Name" label="Requester Name" variation="default" value={username} readOnly required />
          </Flex>

          <Flex direction="row" justifyContent="center">
            <TextField name="requestedLaunchDate" type="datetime-local" placeholder="Requested Launch Date" label="Requested Launch Date"  variation="default" required />           
            <SelectField name="campaignType" label="Campaign Type"  placeholder="Please select a Campagin Type" required>
              <option value="AWARENESS">Awareness</option>
              <option value="RSVP">RSVP</option>
              <option value="NONEVENT">Non-Event</option>
            </SelectField>
            
            </Flex>
        </Flex>
        <Heading level={4}>Campaign Detail</Heading>
        <Flex direction="column" justifyContent="center">
          <Flex direction="row" justifyContent="center">
            <TextField name="subjectLine" placeholder="Subject Line" label="Subject Line"  variation="default" required />
            <TextField name="seedlist" placeholder="Seedlist" label="Seedlist"  variation="default" required />
            <CheckboxField label="Has Legal Signoff?" name="hasLegalSignoff" value="true"/>
            <CheckboxField label="Has Award and Accolades?" name="hasAwardsAndAccolades" value="true"/>
          </Flex>
          <Flex direction="row" justifyContent="center">
            <TextField name="goals" placeholder="Goals" label="Goals"  variation="default" required />
            <TextField name="audience" placeholder="Audience" label="Audience"  variation="default" required />
            <TextField name="approvalList" placeholder="Approval List" label="Approval List"  variation="default" required />
            <TextField name="ctaLink" placeholder="CTA Link" label="CTA Link"  variation="default" required />        </Flex></Flex>
        <Heading level={4}>Event Address</Heading>
        <Flex direction="column" justifyContent="center">
          <Flex direction="row" justifyContent="center">
            <TextField name="venueName" placeholder="Venue Name" label="Venue Name"  variation="default" required />
            <TextField name="venueAddress1" placeholder="Venue Address 1" label="Venue Address 1"  variation="default" required />
            <TextField name="venueAddress2" placeholder="Venue Address 2" label="Venue Address 2"  variation="default" required />
            <TextField name="venueCity" placeholder="Venue City" label="Venue City"  variation="default" required />
          </Flex>          <Flex direction="row" justifyContent="center">
            <TextField name="venueState" placeholder="Venue State" label="Venue State"  variation="default" required />
            <TextField name="venueZip" placeholder="Venue Zip" label="Venue Zip"  variation="default" required />        </Flex></Flex>
        <Heading level={4}>Event Timeline</Heading>
        <Flex direction="column" justifyContent="center">
          <Flex direction="row" justifyContent="center">
            <TextField name="firstReminderDate" type="date" placeholder="First Reminder Date" label="First Reminder Date"  variation="default" required />
            <TextField name="secondReminderDate" type="date" placeholder="Second Reminder Date" label="Second Reminder Date"  variation="default" required />
            <TextField name="rsvpCloseDate" type="date" placeholder="RSVP Close Date" label="RSVP Close Date"  variation="default" required />
            <TextField name="waitlistCloseDate" type="date" placeholder="Waitlist Close Date" label="Waitlist Close Date"  variation="default" required />  </Flex>      </Flex>
        <Heading level={4}>RSVP Detail</Heading>
        <Flex direction="column" justifyContent="center">
          <Flex direction="row" justifyContent="center">
            <TextField name="eventName" placeholder="Event Name" label="Event Name"  variation="default" required />
            <TextField name="ticketType" placeholder="Ticket Type" label="Ticket Type"  variation="default" required />
            <TextField name="ticketRedemptionInst" placeholder="Ticket Redemption Instructions" label="Ticket Redemption Instructions"  variation="default" required />
            <CheckboxField label="Extra CAD Tickets?" name="extraCADTickets" value="true"/>
          </Flex>
        </Flex><br/>
        <Button type="submit" variation="primary">Create Campaign Request</Button>
      </View>
      {/* <View margin="3rem 0">
        {notes.map((note) => (
          <Flex
            key={note.id || note.name}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {note.name}
            </Text>
            <Text as="span">{note.description}</Text>
            <Button variation="link" onClick={() => deleteNote(note)}>
              Delete note
            </Button>
          </Flex>
        ))}
      </View> */}
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(App);