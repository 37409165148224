/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampaignRequest = /* GraphQL */ `
  mutation CreateCampaignRequest($input: CreateCampaignRequestInput!) {
    createCampaignRequest(input: $input) {
      id
      name
      created
      campaignType
      requesterName
      requesterEmail
      requestedLaunchDate
      campaignDetail {
        audience
        subjectLine
        seedlist
        approvalList
        ctaLink
        goals
        hasLegalSignoff
        hasAwardsAndAccolades
      }
      rsvpDetail {
        eventName
        ticketType
        ticketRedemptionInst
        extraCADTickets
      }
      eventTimeline {
        firstReminderDate
        secondReminderDate
        rsvpCloseDate
        waitlistCloseDate
      }
      eventAddress {
        venueName
        venueAddress1
        venueAddress2
        venueCity
        venueState
        venueZip
      }
    }
  }
`;
